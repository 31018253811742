import toast from "react-hot-toast";

import { t } from "i18next";

export function getErrorMessage(error) {
  if (!error) return t("An unknown error occurred.");

  if (typeof error === "string") {
    return t(error);
  }

  if (error.response && error.response.data) {
    const data = error.response.data;
    if (typeof data === "string") return t(data);
    if (data.message) return t(data.message);
    if (data.error) return t(data.error);
    if (typeof data === "object") return Object.values(data).map(t).join("\n");
  }

  if (error.message) return t(error.message);

  return t("An unknown error occurred.");
}

export function handleError(error) {
  const errorMessage = getErrorMessage(error);
  toast.error(errorMessage);
}