import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Configurator from "./components/Configurator";
import routes from "./routes";
import Sidenav from "./components/Sidenav";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import MDBox from "./components/MDBox";
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "./context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Icon from "@mui/material/Icon";
import toast, { Toaster } from "react-hot-toast";
import { setupAxiosInterceptors } from "./services/http/interceptor";
import { useKeycloak } from "@react-keycloak/web";
import useBeforeUnload from "./listeners/usage.listeners";
import UsageService from "../src/services/licence/usages.endpoint";
import TimeOnSiteTracker from "./TimeOnSiteTracker";
import { useTranslation } from "react-i18next";
import CoursesEndpoint from "./services/course/courses.endpoint";
import Courses from "./pages/courses";
import { config } from "./Constants";
import Lessons from "./pages/lessons";
import Scenarios from "./pages/scenarios";
import CompletedLessons from "./pages/completedLessons";
import PlannedLessons from "./pages/plannedLessons";
import OngoingLessons from "./pages/ongoingLessons";
import {handleError} from "./utils/static/ErrorMessage";

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [updatedRoutes, setUpdatedRoutes] = useState(routes);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation([], { useSuspense: false });

  const SendRequest = () => {
    const usageData = {
      type: "SCREENTIME",
      sessionId: window.Tos.TOSSessionKey,
      amount: window.Tos.timeOnSite / 1000,
    };
    UsageService.addUsage(keycloak.token, usageData);
  };

  useBeforeUnload(SendRequest);

  useEffect(() => {
    const intervalId = setInterval(() => {
      SendRequest();
    }, 600000);
    return () => clearInterval(intervalId);
  }, [keycloak.token]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    CoursesEndpoint.findStudentAllowedCourses(keycloak.token)
      .then((response) => {
        const courseRoutes = [
          ...routes,
          ...response.map((course) => ({
            type: "internal",
            route: `/courses/${course.id}`,
            component: <Courses element={course} />,
          })),
          {
            type: "internal",
            route: `${config.routes.courses.route}/:courseId/lessons/:lessonId`,
            component: <Lessons />,
          },
          {
            type: "internal",
            route: `${config.routes.courses.route}/:courseId/lessons/scenario/:lessonId`,
            component: <Scenarios />,
          },
          {
            type: "internal",
            route: config.routes.completed_lessons.route,
            component: <CompletedLessons />,
          },
          {
            type: "internal",
            route: config.routes.planned_lessons.route,
            component: <PlannedLessons />,
          },
          {
            type: "internal",
            route: config.routes.ongoing_lessons.route,
            component: <OngoingLessons />,
          },
        ];
        setUpdatedRoutes(courseRoutes);
      })
        .catch((error) => {
          handleError(error);
        });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (
        route.permission &&
        !keycloak.permissions.includes(route.permission)
      ) {
        return null;
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    if (keycloak.isTokenExpired()) {
      keycloak
        .updateToken()
        .then(() => {
          console.log("token refreshed");
        })
        .catch(() => {
          toast.error("Your session has expired, please login again");
          navigate("/");
          keycloak.logout();
        });
    }
  });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Toaster />
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName={t("Cyberlab")}
            routes={updatedRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(updatedRoutes)}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <TimeOnSiteTracker />
    </ThemeProvider>
  );
}

export default App;
