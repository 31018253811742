import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import AppCardHeader from "../../components/AppCardHeader";
import { useKeycloak } from "@react-keycloak/web";
import dashboardEndpoint from "../../services/dashboard/dashboard.endpoint";
import toast from "react-hot-toast";
import { config } from "../../Constants";
import DataTable from "../../components/Tables/DataTable";
import {handleError} from "../../utils/static/ErrorMessage";

function PlannedLessons({}) {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation([], { useSuspense: false });
  const [data, setData] = React.useState([]);

  useEffect(() => {
    dashboardEndpoint
      .userPlannedLessons(keycloak.token)
      .then((res) => {
        setData(res);
      })
        .catch((error) => {
            handleError(error);
        });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <AppCardHeader title={t("Planned Lessons")} />
              <MDBox pt={3}>
                <DataTable
                  isSorted={false}
                  canSearch
                  pagination
                  noDataComponent={t("No data available")}
                  table={{
                    columns: [
                      {
                        Header: t("Course Name"),
                        accessor: "name",
                        align: "center",
                        Cell: ({ row }) => {
                          return (
                            <Link
                              to={`${config.routes.courses.route}/${row.original.courseId}/lessons/${row.original.id}`}
                              state={{
                                path: row.original.name,
                                rootPath: row.original.courseId,
                              }}
                            >
                              {row.original.name}
                            </Link>
                          );
                        },
                      },
                      {
                        Header: t("Description"),
                        accessor: "description",
                        align: "center",
                      },
                    ],
                    rows: data || [],
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PlannedLessons;
